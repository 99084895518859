import { UploadFile } from 'element-plus';

interface Protocol<T> {
  total: number;
  pageSize: number;
  pageNum: number;
  records: T[];
}

//登录
export const LOGIN_URL = '/admin/v1/login';
export interface LoginReq {
  username: string;
  password: string;
}
export interface LoginResp {
  name: string;
  token: string;
}

//获取题目列表
export const GET_QUESTION_LIST_URL = '/game/v1/getGameList';
export interface GetQuestionListReq {
  pageSize: number;
  pageNum: number;
}

//获取账号列表
export const ACCOUNT_LIST = '';
export interface accountListReq {
  sortOrder: string;
  limit: number;
  offset: number;
  name?: string;
  account?: string;
  startDate?: string;
  endDate?: string;
}
export interface accountListResp {
  currentPage: number;
  totalCount: number;
  totalPages: number;
  accounts: accountListData[];
}
export interface accountListData {
  id: number; // 主键
  date: string; // 日期
  name: string; // 名字
  account: string; // 账号
  password: string; // 密码
  phoneNumber: string; // 绑定手机号
  clientName: string; // 客户名字
  deliveryDate: string; // 交付日期
  recruiterName: string; // 招聘人
  idCardFront: string; // 身份证正面
  idCardBack: string; // 身份证反面
  createdAt: Date; // 创建时间
  endAt: Date; // 创建时间
}

/**
 * 登陆
 */
export const LOGIN = '/login';
export interface LoginReq {
  username: string;
  password: string;
}

//添加账号
export const ADD_ACCOUNT = '';
export interface AddAccountReq {
  name: string; // 名字
  account: string; // 账号
  password: string; // 密码
  phoneNumber: string; // 绑定手机号
  clientName: string; // 客户名字
  deliveryDate: string; // 交付日期
  recruiterName: string; // 招聘人
  idCardFront: string; // 身份证正面
  idCardBack: string; // 身份证反面
}
//修改账号
export const UPDATE_ACCOUNT = '/update/';
//删除账号
export const DELECT_ACCOUNT = '/delete/';

//删除题目
export const DELETE_QUESTION_URL = '/game/v1/deleteGame';
export interface DeleteQuestionReq {
  id: number;
}

//获取网站配置
export const GET_SITE_CONFIG_URL = '/webConfig/v1/getWebConfig';
export interface GetSiteConfigReq {
  pageSize: number;
  pageNum: number;
}
export interface SiteConfigItem {
  id: number;
  key: string;
  value: string;
  enable: number;
  createTime: string;
  updateTime: string;
}
export type GetSiteConfigResp = Protocol<SiteConfigItem>;

/**
 * 获取服务器时间
 */
export const GET_SERVER_TIME_URL = '/webConfig/v1/timestamp';
export interface GetServerTimeResp {
  data: string;
}

/**
 * excel导入
 */
export const UPLOAD_GAME_EXCEL = '/game/v1/uploadGameExcel';
export interface uploadGameExcelReq {
  file: UploadFile;
}
