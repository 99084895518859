import { post, get } from '@/api/api-service';
import {
  ACCOUNT_LIST,
  accountListReq,
  accountListResp,
  AddAccountReq,
  DELECT_ACCOUNT,
  DELETE_QUESTION_URL,
  DeleteQuestionReq,
  GET_QUESTION_LIST_URL,
  GET_SERVER_TIME_URL,
  GET_SITE_CONFIG_URL,
  GetQuestionListReq,
  GetServerTimeResp,
  GetSiteConfigReq,
  GetSiteConfigResp,
  LOGIN,
  LOGIN_URL,
  LoginReq,
  LoginResp,
  UPDATE_ACCOUNT,
  UPLOAD_GAME_EXCEL,
  uploadGameExcelReq,
} from './protocol';
import { UploadFile } from 'element-plus';
import Login from '../login/Login.vue';

//管理员登录接口
export async function fetchAdminLogin(username: string, password: string) {
  try {
    return await post<LoginResp, LoginReq>(LOGIN_URL, {
      username,
      password,
    });
  } catch (error) {
    return undefined;
  }
}

/**获取账号列表 */
export async function accountList(data: accountListReq) {
  try {
    return await get<accountListResp, accountListReq>(ACCOUNT_LIST, data);
  } catch (error) {
    return undefined;
  }
}
export async function addAccount(data: AddAccountReq) {
  try {
    return await post<string, AddAccountReq>(ACCOUNT_LIST, data);
  } catch (error) {
    return undefined;
  }
}
export async function updateAccount(id: number, data: AddAccountReq) {
  try {
    return await post<string, AddAccountReq>(UPDATE_ACCOUNT + id, data);
  } catch (error) {
    return undefined;
  }
}
export async function deleteAccount(id: number) {
  try {
    return await post<string, AddAccountReq>(DELECT_ACCOUNT + id);
  } catch (error) {
    return undefined;
  }
}

/**删除题目 */
export async function fetchDeleteProblem(id: number) {
  try {
    return await get<string, DeleteQuestionReq>(DELETE_QUESTION_URL, {
      id,
    });
  } catch (error) {
    return undefined;
  }
}

/**
 * 登陆
 */
export async function login(data: LoginReq) {
  try {
    return await post<string, LoginReq>(LOGIN, data);
  } catch (error) {
    return undefined;
  }
}

/**
 * 获取网站配置
 * @param pageSize
 * @param pageNum
 * @returns
 */
export async function fetchSiteConfig(pageSize: number, pageNum: number) {
  try {
    return await get<GetSiteConfigResp, GetSiteConfigReq>(GET_SITE_CONFIG_URL, {
      pageSize,
      pageNum,
    });
  } catch (error) {
    return undefined;
  }
}

/**
 * 获取服务器时间
 */
export async function fetchServerTime() {
  try {
    const res = await post(GET_SERVER_TIME_URL);
    return res;
  } catch (error) {
    return undefined;
  }
}

/**
 * excel导入
 */
export async function uploadGameExcel(file: any) {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const res = await post(UPLOAD_GAME_EXCEL, formData);
    return res;
  } catch (error) {
    return undefined;
  }
}
