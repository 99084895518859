import { TIME_SERVICE } from './const';

class TimeService {
  timediff: number;
  constructor() {
    this.timediff = Number(localStorage.getItem(TIME_SERVICE));
  }
  updateTimeDiff(timediff: number) {
    if (timediff === this.timediff) {
      return;
    }
    this.timediff = timediff;
    localStorage.setItem(TIME_SERVICE, String(timediff));
  }
  updateTimeDiffBy(timediff: number) {
    this.updateTimeDiff(timediff);
  }
}
export const timeService = new TimeService();
