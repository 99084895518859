import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted } from 'vue';
import { timeService } from './manager/time-service';
import { fetchServerTime, login } from './views/api';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

onMounted(() => {
  fetchServerTime().then((resp) => {
    if (!resp) return;
    const localTime = new Date().getTime();
    const timeOffset = resp - localTime;
    timeService.updateTimeDiffBy(timeOffset);
  });
});

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view))
}
}

})