import { TOKEN_KEY } from './const';

class TokenService {
  token: string | null;
  constructor() {
    this.token = localStorage.getItem(TOKEN_KEY);
  }
  updateToken(token: string) {
    if (this.token === token) {
      return;
    }
    localStorage.setItem(TOKEN_KEY, token);
    this.token = token;
  }
  update(token: string) {
    this.updateToken(token);
  }
}
export const tokenService = new TokenService();
