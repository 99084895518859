import { tokenService } from '@/manager/token-service';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import qs from 'qs';
import router from '@/router';
import { ElMessage } from 'element-plus';
import { getCurrentTime } from '@/utils/responsive';
import { GET_SERVER_TIME_URL } from '@/views/api/protocol';
/**
 * 创建axios实例
 */
const service: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_SERVER,
  timeout: 20 * 1000, // 请求超时时间
  headers: { 'Content-Type': 'application/json;charset=UTF-8' },
});
service.interceptors.request.use((config) => {
  // 这里可以设置token: config!.headers!.Authorization = token
  config.headers.auth = tokenService.token;
  return config;
});
/**
 * 响应拦截器
 */
service.interceptors.response.use(
  (response: AxiosResponse) => {
    const res = response.data;
    if (res.code === 200) {
      return res.data;
    } else {
      if (res.code === 2002) {
        router.push('/login');
      }
      ElMessage.error(res.message);
      return undefined;
    }
  },
  (error) => {
    if (error.config && error.config.headers && error.config.headers.auto) {
      ElMessage.error(error.message || '请求失败');
    }
    return undefined;
  },
);

export async function get<R = any, T = any>(
  url: string,
  params?: T,
  header?: Record<string, any>,
): Promise<R> {
  const query = params ? qs.stringify(params) : undefined;
  const connectSymbol = url.includes('?') ? '&' : '?';
  return await service.get(
    query ? `${url}${connectSymbol}${query}` : url,
    header,
  );
}

export async function post<R = any, T = any>(
  url: string,
  data?: T,
  header?: Record<string, any>,
): Promise<R> {
  return await service.post(url, data, header);
}
export async function update<R = any, T = any>(
  url: string,
  data?: T,
  header?: Record<string, any>,
): Promise<R> {
  return await service.put(url, data, header);
}
