import { tokenService } from '@/manager/token-service';
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/login/Login.vue'),
  },
  {
    path: '/',
    name: 'dashboard',
    // component: () =>
    //   import(/* webpackChunkName: "dashboard" */ '../views/Layout.vue'),
    children: [
      {
        path: '',
        name: 'ListOfTopics',
        component: () =>
          import(
            /* webpackChunkName: "ListOfTopics" */ '../views/QuestionBank/ListOfTopics.vue'
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next();
  } else if (!tokenService.token) {
    next('/login');
  } else {
    next();
  }
});
export default router;
