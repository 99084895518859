import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
// 引入全局样式
import './assets/styles/reset.scss'; // 样式重置
import './assets/styles/mixins.scss'; // mixin
import './assets/styles/base.scss'; // 基础样式
import './assets/styles/typography.scss'; // 字体样式
import { setRootFontSize } from './utils/responsive';

// 调用 setRootFontSize 调整字体大小
// setRootFontSize();

// // 监听窗口大小的变化，在变化时重新调整字体大小
// window.addEventListener('resize', setRootFontSize);

createApp(App).use(ElementPlus, { locale: zhCn }).use(router).mount('#app');
