<template>
  <router-view />
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { timeService } from './manager/time-service';
import { fetchServerTime, login } from './views/api';

onMounted(() => {
  fetchServerTime().then((resp) => {
    if (!resp) return;
    const localTime = new Date().getTime();
    const timeOffset = resp - localTime;
    timeService.updateTimeDiffBy(timeOffset);
  });
});
</script>
